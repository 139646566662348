import React from 'react'

const BannerLanding = (props) => (
    <section id="banner" className="style2">
        <div className="inner">
            <header className="major">
                <h1>The Gemerator</h1>
            </header>
            <div className="content">
                <p>A nice little application for generating large numbers of<br /> 
                stickers for Augmented Reality content</p>
            </div>
        </div>
    </section>
)

export default BannerLanding
